import React from "react";

import btM from "./assets/images/btM.png";
import opM from "./assets/images/opM.png";
import trM from "./assets/images/trM.png";

const HomeImages1 = (props) => {
  return (
    <div className="col d-none d-md-inline-block">
      <div className="home-img">
        <img className="left-slide" src={trM} alt="EasyCryptoBot Crypto Trading Bot" />
        <img className="right-slide" src={btM} alt="EasyCryptoBot Crypto Trading Bot" />
        <img className="" src={opM} alt="EasyCryptoBot Crypto Trading Bot" />
      </div>
    </div>
  );
};

export default HomeImages1;
